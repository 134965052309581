<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>
  <div id="maincontent_container">
    <p>Please enter the email address you have used for this application. We will resend you the application link for you to continuing your application or check case status.</p>
    <p>To start online application with a new email address, <a href="signup">click here</a>.</p>
    
    <div class = "alert alert-danger" v-if="error">
      {{error}}
    </div>
    <div v-if= "message" class=" alert alert-success">
      {{message}}
    </div>
    
    <form @submit.prevent="handleSubmission"> <!--Prevents default action from being taken-->
      <label class="fw-bold"> Email: </label>
      <input type = "email" id = "email" class = "form-control" required v-model = "email">
      <br>
      <button class = "btn btn-primary" @click="handleSubmit" id="send" type="submit" >Submit</button>
    </form>
    <!-- <b>Email:</b>
    <br>
    <input type="text" name="email" id="1" class="form"> -->
  
  </div>
</template>

<script>
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import { ref } from 'vue'
import {getAPI} from '../../utils/axios-api.js'



export default {
  name: "ReturnUser",
  components: { BreadcrumbPage },
  props: [ 'id' ],
  setup(props) {
    console.log("----------------123", props.id)
    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Home", link: "Home"})
    breadcrumb_links.value.push({text: "Become a reseller", link: "BecomeReseller"})
    breadcrumb_links.value.push({text: "Online Application", link: ""})

    let email = ref('')
    let message = ref('')
    let error = ref(null)
    
    const handleSubmit = () => {


      let payload = {
        "email" : email.value
      }

      getAPI
      .post('/application/returnuser/', payload)
      .then(response => {
        //check response data
        //console.log("-----response data-----", response)
        
        message.value = response.data["message"],
        error.value = null
      })
      .catch(err => {
        //check error response 
        //console.error(err.response)
        
        error.value = err.response.data["message"]
      })
      
    }

    return {
        breadcrumb_links,
        handleSubmit,
        email,
        message,
        error
    }
  }
}
</script>

<style>

</style>